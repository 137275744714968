import React, { Component } from 'react';
import SearchBar from './SearchBar';

import Modal from './Popups/Modal';
import Error from './Popups/Error';

import './Form.css';

class Form extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
      queryURL: '',
      error: '',
      courses: [],
      submitted: false
    }
  }
  
  inputChange = (e) => {
    var inputRow = e.target.parentNode.childNodes;
    var isRequired = false;
    
    for (let i = 0; i < inputRow.length; i++) {
      if(inputRow[i].value) {
        isRequired = true;
      }
    }
    
    for (let i = 0; i < 2; i++) {
      inputRow[i].required = isRequired;
    }
  }
  
  submitForm = (e) => {
    e.preventDefault();
    const scriptURL = 'https://script.google.com/macros/s/AKfycbzIodjhUX2trXjB7l2pRKQLTMsYW_ZBzDwN82tj_73tdHPsGSY/exec';
    const fields = document.getElementsByTagName('input');  
    
    var validSubmission = true;
    var courseInputted = false;
    var submitURL = `${scriptURL}?`;
    
    for (let i = 0; i < fields.length; i++) {
      let currField = fields[i];
      
      if (currField.value && currField.id !== 'course-search') {
        submitURL = submitURL + `${currField.id}=${encodeURIComponent(currField.value)}&`;
        
        if (!courseInputted && currField.id.includes('course')) {
          courseInputted = true;
        }
      }
      else if (currField.required && !currField.value) {
        validSubmission = false;
      }
    }
    
    submitURL = submitURL + `mode=${encodeURIComponent(this.props.mode)}`;
    
    if (validSubmission && courseInputted) {
      this.setState({ queryURL: submitURL });
      var modal = document.getElementById("myModal");
      modal.style.display = "block";
    }
    else {
      var errMsg = 'Please fill in all required fields and enter at least one course.';
      console.error(errMsg);
      this.setState({error: errMsg});
    }
  }
  
  redirect = (newState) => {
    this.setState({ submitted: newState });
    
    this.props.updateStatus(newState);
  }
  
  addCourse = (newCourse) => {
    var temp = this.state.courses;
    temp.push(newCourse);
    
    this.setState({ courses: temp });
  }
  
  deleteCourse = (e) => {
    var code = e.target.parentNode.childNodes[0].value;
    
    var courses = this.state.courses.filter(course => course.code !== code);
    
    this.setState({ courses: courses });
  }
  
  render() {
    const courseInputs = [];
    
    for (let i = 0; i < this.state.courses.length; i++) {
      courseInputs.push(
        <div key={i} className="form-fields flexbox">
          <input type="text" id={`course-code-${i}`} name={`course-code-${i}`} placeholder={`Code ${i}`} onChange={this.inputChange} value={this.state.courses[i].code} disabled></input>
          <input type="text" id={`course-name-${i}`} name={`course-name-${i}`} placeholder={`Name ${i}`} onChange={this.inputChange} value={this.state.courses[i].name} disabled></input>
          {(this.props.mode === 'tutor') ? 
            <input type="text" id={`course-grade-${i}`} name={`course-grade-${i}`} placeholder={`Grade ${i}`}></input>
            : ''
          }
          <i className="material-icons delete-icon" onClick={this.deleteCourse}>delete</i>
        </div>
      )
    }
    
    return (
      <div className="Form">
        <main>
          <form name="csa-form" autoComplete="off" onSubmit={this.submitForm}>
            <section>
              <div className="row">
                <h2 id="instructions" className="row">
                  Please fill out the information below and we will reach out shortly
                </h2>
                
                <p id="instruction-details">
                  { this.props.mode === 'tutee' ? 'Pricing and available times will be included in an email after the form is submitted.' :
                  'Interview times will be included in an email after the form is submitted.' }
                </p>
              </div>
              
              <div className="row">
                <div className="form-labels flexbox">
                  <label>First Name</label>
                  <label>Last Name</label>
                </div>
                <div className="form-fields flexbox">  
                  <input type="text" id="firstName" name="firstName" placeholder="First" required></input>
                  <input type="text" id="lastName" name="lastName" placeholder="Last" required></input>
                </div>
              </div>
              
              <div className="row form-fields">
                <label id="email-label">Queen's Email</label>
                <input id="queens-email" type="email" name="queens-email" placeholder="Email" required></input>
              </div>
            </section>
            
            <section>
              <div className="row">
                <SearchBar courses={this.state.courses} addCourse={this.addCourse}/>
                
                <div id="table-labels" className="form-labels flexbox">
                  <label>Course Code</label>
                  <label>Course Name</label>
                  {(this.props.mode === 'tutor') ? 
                    <label>Grade in Course (Optional)</label>
                    : ''
                  }
                </div>
                
                {courseInputs}
                
                <p id="note">Please enter all courses you would like {this.props.mode === 'tutee' ? 'a' : 'to'} tutor for</p>
              </div>
              
              <div className="row">
                <button id="submit-btn" className="btn" onClick={this.submitForm}>Submit</button>
              </div>
            </section>
          </form>
        </main>
        
        <Modal queryURL={this.state.queryURL} submitStatus={this.state.submitted} redirect={this.redirect}/>
        <Error error={this.state.error}/>
      </div>
    );
  }
}

export default Form;


/*
<div id="netId" className="row flexbox">
  <p id="optional">Optional: Logging in with your netID will allow us to pull your
    class schedule and pair you with {this.props.mode === 'tutee' ? 'tutors' : 'students'} much faster</p>
  <button type="button" id="netId-btn" className="btn">Login with NetID</button>
  <span className="vert-aligner"></span>
</div>
*/