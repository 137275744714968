import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import './Success.css';

class Success extends Component {
  
  renderRedirect = () => {
    if (this.props.submitStatus === false) {
      return <Redirect to='/' />
    }
  }
  
  render() {
    return (
      <div className="Success">
        {this.renderRedirect()}
        <h1>Success!</h1>
        <p>Thanks for filling out the form, we'll be in touch with you shortly{this.props.mode === 'tutee' ? ' to connect you with a tutor': ''}!</p>
      </div>
    )
  }
}

export default Success;