module.exports = [
  {
    code: 'CISC 110',
    name: 'Creative Computing',
    availability: 'F'
  },
  {
    code: 'CISC 251/CMPE 333',
    name: 'Data Analytics',
    availability: 'F'
  },
  {
    code: 'CISC 282',
    name: 'Fundamentals of Web Development',
    availability: 'F'
  },
  {
    code: 'CISC 101',
    name: 'Elements of Computing Science',
    availability: 'F/S/W'
  },
  {
    code: 'CISC 102',
    name: 'Discrete Mathematics for Computing I',
    availability: 'F/S/W'
  },
  {
    code: 'CISC 121',
    name: 'Introduction to Computing Science I',
    availability: 'F/S/W'
  },
  {
    code: 'CISC 124',
    name: 'Introduction to Computing Science II',
    availability: 'F/S/W'
  },
  {
    code: 'CISC 181',
    name: 'Digital Societies',
    availability: 'F/W'
  },
  {
    code: 'CISC 203',
    name: 'Discrete Mathematics for Computing II',
    availability: 'F/W'
  },
  {
    code: 'CISC 204/CMPE 204',
    name: 'Logic for Computing Science',
    availability: 'F/W'
  },
  {
    code: 'CISC 220',
    name: 'System Level Programming',
    availability: 'F/W'
  },
  {
    code: 'CISC 221',
    name: 'Computer Architecture',
    availability: 'F/W'
  },
  {
    code: 'CISC 235',
    name: 'Data Structures',
    availability: 'F/W'
  }
]