import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Logo from '../../assets/images/logo.svg';

import './Header.css';

class Header extends Component {
  render() {
    return (
      <header className="Header">
        <Link to="/">
          <img id="brand-logo" src={ Logo } alt="Compsci Advantage logo"/>
        </Link>
        
        <div className="headings">
          <h1 className="title">Welcome to CompSci Advantage</h1>
          <h2 className="sub-title">{this.props.mode === 'tutee' ? 'Request' : 'Become'} a Tutor</h2>
        </div>
      </header>
    );
  }
}

export default Header;