import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import './Modal.css';

class Modal extends Component {

  outerClick = (e) => {
    if (e.target === document.getElementById('myModal'))
      e.target.style.display = 'none';
  }
  
  closeModal = () => {
    var modal = document.getElementById('myModal');
    modal.style.display = 'none';
  }
  
  submitClick = () => {
    axios({
      method: 'get',
      url: this.props.queryURL
    })
    this.closeModal();
    
    this.props.redirect(true);
  }
  
  renderRedirect = () => {
    if (this.props.submitStatus) {
      return <Redirect to='/Success' />
    }
  }

  render() {
    return (
      <div id="myModal" className="modal" onClick={this.outerClick}>
        {this.renderRedirect()}
        <div className="modal-content">
          <div className="modal-header">
            <span className="close" onClick={this.closeModal}>&times;</span>
            <h2>Comp Sci Advantage</h2>
          </div>
          
          <div className="modal-body">
            <p>Are you sure you want to submit?</p>
          </div>
          
          <div className="modal-footer">
            <button id="cancel-btn" className="btn modal-btn" onClick={this.closeModal}>Cancel</button>
            <button id="submit-btn" className="btn modal-btn" onClick={this.submitClick}>Submit</button>
          </div>
        </div>
      </div>
    )
  }
}

export default Modal