import React, { Component } from 'react';
import './Error.css';

class Error extends Component {
  closeError = (e) => {
    var parentDiv = e.target.parentNode;
    parentDiv.style.opacity = "0";
    setTimeout(function(){ parentDiv.style.display = "none"; }, 600);
  }
    
  render() {
    return (
      <div>
      { this.props.error ? 
        <div className="alert">
          <span className="closebtn" onClick={this.closeError}>&times;</span>
          <strong>Error:</strong> {this.props.error}
        </div> 
        : '' }
      </div>
    )
  }
}

export default Error;