import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import Header from './components/Header/Header';
import Form from './components/Form/Form';
import Success from './components/Success/Success';

import './App.css';

class App extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      mode: 'tutee',
      submitted: false
    }
  }
  
  toggleForm = () => {
    var newMode = this.state.mode === 'tutee' ? 'tutor' : 'tutee';
    this.setState({ mode: newMode });
    
    const fields = document.getElementsByTagName('input');
    
    for (let i = 0; i < fields.length; i++) {
      let currField = fields[i];
      
      if (currField.value && currField.id.includes('course')) {
        var fieldRow = currField.parentNode.childNodes;
        var isRequired = false;

        for (let j = 0; j < fieldRow.length; j++) {
          if (fieldRow[j].value) {
            isRequired = true;
          }
        }
        
        for (let j = 0; j < 2; j++) {
          fieldRow[j].required = isRequired;
        }
      }
    }
  }
  
  updateStatus = (newState) => {
    this.setState({ submitted: newState })
  }
  
  render() {
    return (
      <Router onUpdate={() => window.scrollTo(0, 0)}>
        <div className="App">
          <Header mode={this.state.mode}/>
          
          <Switch>
            <Route path="/" exact
              render={(props) => <Form {...props} mode={this.state.mode} updateStatus={this.updateStatus}/>}
            />
            <Route path="/Success"
              render={(props) => <Success {...props} mode={this.state.mode} submitStatus={this.state.submitted}/>}
            />
          </Switch>
          
          <footer>
            <div className="toggler">
              <p>Interested in <span className="bold-italic">{this.state.mode === 'tutee' ? 'becoming ' : 'finding '}</span> 
                a tutor? Click <Link to="/" className="link" onClick={this.toggleForm}>here</Link></p>
            </div>
          </footer>
        </div>
      </Router>
    );
  }
}

export default App;
