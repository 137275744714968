import React, { Component } from 'react';

import './SearchBar.css';

const courses = require('./courses');

class SearchBar extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      courses: this.props.courses,
      course: {}
    }
  }
  
  
  onInput = (e) => {
    var a, b;
    var val = e.target.value;
    var inp = e.target;
    
    this.closeAllLists(inp);
    
    if (!val) { return false;}

    a = document.createElement("DIV");
    a.setAttribute("id", "autocomplete-list");
    a.setAttribute("class", "autocomplete-items");

    e.target.parentNode.appendChild(a);
    
    for (let i = 0; i < courses.length; i++) {
      if (courses[i].code.toUpperCase().includes(val.toUpperCase()) && !this.state.courses.includes(courses[i])) {
        b = document.createElement("DIV");
        b.innerHTML += courses[i].code;
        b.innerHTML += "<input type='hidden' value='" + courses[i].code + "'>";
        b.addEventListener("click", (e) => {
            inp.value = '';
            this.setState({ course: courses[i] });
            this.props.addCourse(courses[i]);
            this.closeAllLists(inp);
        });
        a.appendChild(b);
      }
    }
  }

  closeAllLists = (inp, elmnt) => {
    var x = document.getElementsByClassName("autocomplete-items");
    for (var i = 0; i < x.length; i++) {
      if (elmnt !== x[i] && elmnt !== inp) {
        x[i].parentNode.removeChild(x[i]);
      }
    }
  }
  
  render() {
    return (
        <div id="search-bar" className="autocomplete row form-fields">
          <label id="search-bar-label">Course Code</label>
          <input id="course-search" type="text" name="course-search" placeholder="Please type a course code" onChange={this.onInput}></input>
        </div>
    )
  }
}

export default SearchBar;